/* eslint-disable default-param-last */
import { types } from "@/types"

const initialState = {
  saleOrderId: null,
  basket: {},
  confirm: {},
  checkout: null,
  deliveryDate: null,
  paypal: {
    noFeeTotal: 0,
    feeAmount: 0,
    feePerc: 0,
  },
  insurance: 0,
  coupon: [],
  currencies: [],
  currency: {
    id: 1,
    symbol: `€`,
    code: `EUR`,
    translate: {
      es: `Euro`,
      fr: `Euro`,
      de: `Euro`,
      pt: `Euro`,
      en: `Euro`,
      it: `Euro`
    },
    exchange: 1
  },
}
export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.cart.restart:
    return initialState
  case types.initCart:

    return {
      ...state,
      saleOrderId: action.payload,
    }
  case types.recoveryCart:
  case types.cartSetVariant:
  case types.cartRemoveVariant:
    return {
      ...state,
      saleOrderId: action.payload.id,
      basket: action.payload,
    }
  case types.confirmCart:
    return {
      ...state,
      saleOrderId: action.payload.id,
      confirm: action.payload,
      basket: {},
    }
  case types.cartCheckout:
    return {
      ...state,
      checkout: action.payload,
    }

  case types.cart.paypal:
    return {
      ...state,
      paypal: action.payload,
    }
  case types.cart.ipinfo:
    return {
      ...state,
      ipInfo: action.payload,
    }
  case types.cart.insurance:
    return {
      ...state,
      insurance: action.payload,
    }
  case types.cart.cartCoupon:
    return {
      ...state,
      coupon: action.payload,
    }
  case types.cart.currencies:
    return {
      ...state,
      currencies: action.payload,
    }
  case types.cart.currency:
    return {
      ...state,
      currency: action.payload,
    }

  case types.cart.deliveryDate:
    return {
      ...state,
      deliveryDate: action.payload,
    }

  case types.cart.cartDrawer:
    return {
      ...state,
      cartDrawerOpen: action.payload,
    }

    // case types.cart.addRemove:
    //   return {
    //     ...state,
    //     saleOrderId: action.payload.id,
    //     basket: action.payload,
    //   }
  default:
    return state
  }
}
